export default {
  headers: [
    {
      id: 'main-header',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/rivanj/logo.png',
        url: 'https://oldhamgroupluxury.com/',
      },
      breakpoint: 999999,
      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: false,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: '',
        },
        headerClassName: '',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: '',
          imageClassName: 'on-rivanj-header-logo',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: 'on-rivanj-header-hamburger',
          hide: false,
          reverse: false,
        },
        headerClassName: 'on-rivanj-header',
        headerSectionsClassName: 'on-rivanj-header-section',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: 'on-rivanj-header-menu',
        itemClassName: 'on-rivanj-header-item',
        menuClassName: 'on-rivanj-header-folder',
        menuLabelActiveClassName: '',
        menuItemClassName: 'on-rivanj-header-folder-item',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [
          {
            id: 'rivanj-about-item',
            content: 'About Us',
            url: 'https://oldhamgroupluxury.com/team',
          },
          {
            id: 'rivanj-listings-item',
            content: 'Our Listings',
            url: 'https://oldhamgroupluxury.com/properties/sale',
          },
          {
            id: 'rivanj-transactions-item',
            content: 'Past transactions',
            url: 'https://oldhamgroupluxury.com/properties/sold',
          },
          {
            id: 'rivanj-neighborhoods-item',
            content: 'Neighborhoods',
            url: 'https://oldhamgroupluxury.com/neighborhoods',
          },
          {
            id: 'rivanj-contact-us-item',
            content: 'Contact Us',
            url: 'mailto:theoldhamgroup@compass.com',
          },
        ],
        items: [
          {
            id: 'rivanj-menu-items',
            menuItems: [
              {
                id: 'rivanj-menu-items-home',
                content: 'Home',
                url: 'https://oldhamgroupluxury.com/home',
                className: 'on-rivanj-header-item on-rivanj-header-item-home',
              },
              {
                id: 'rivanj-menu-items-about-us',
                content: 'About Us',
                url: 'https://oldhamgroupluxury.com/team',
              },
              {
                id: 'rivanj-menu-items-village',
                content: "Village at Gray's Crossing",
                url: 'https://villageatgrays.com/',
              },
              {
                id: 'rivanj-menu-items-programs',
                content: 'Buyer & Seller Programs',
                menuItems: [
                  {
                    id: 'overview-submenu-item',
                    content: 'Program Overview',
                    url: 'https://oldhamgroupluxury.com/ogprograms',
                  },
                  {
                    id: 'simple-sale-submenu-item',
                    content: 'Simple Sale',
                    url: 'https://oldhamgroupluxury.com/neighborhoods/simple-sale',
                  },
                  {
                    id: 'trade-in-submenu-item',
                    content: 'Trade-In Program',
                    url: 'https://trade-in.oldhamgroupluxury.com',
                  },
                  {
                    id: 'cash-offer-submenu-item',
                    content: 'All Cash Offer',
                    url: 'https://cashoffer.oldhamgroupluxury.com',
                  },
                  {
                    id: 'concierge-submenu-item',
                    content: 'Concierge',
                    url: 'https://oldhamgroupluxury.com/concierge',
                  },
                ],
              },
              {
                id: 'rivanj-menu-items-blog',
                content: 'OG Blog',
                url: 'https://oldhamgroupluxury.com/blog',
              },
              {
                id: 'rivanj-menu-items-guide',
                content: "Buyer's Guide",
                url: 'https://oldhamgroupluxury.com/ebook',
              },
              {
                id: 'rivanj-menu-items-properties',
                content: 'Properties',
                menuItems: [
                  {
                    id: 'rivanj-menu-properties-listings',
                    content: 'Our Listings',
                    url: 'https://oldhamgroupluxury.com/properties/sale',
                  },
                  {
                    id: 'rivanj-menu-properties-transactions',
                    content: 'Past Transactions',
                    url: 'https://oldhamgroupluxury.com/properties/sold',
                  },
                ],
              },
              {
                id: 'rivanj-menu-items-home-search',
                content: 'Home Search',
                url: 'https://oldhamgroupluxury.com/home-search/listings',
              },
              {
                id: 'rivanj-menu-items-curated',
                content: 'Compass Curated',
                url: 'https://oldhamgroupluxury.com/compass-curated',
              },
              {
                id: 'rivanj-menu-items-concierge',
                content: 'Compass Concierge',
                url: 'https://oldhamgroupluxury.com/concierge',
              },
              {
                id: 'rivanj-menu-items-menu-neighborhoods',
                content: 'Neighborhoods',
                url: 'https://oldhamgroupluxury.com/neighborhoods',
              },
              {
                id: 'rivanj-menu-items-prop',
                content: 'Prop 19',
                url: 'https://oldhamgroupluxury.com/prop19',
              },
              {
                id: 'rivanj-menu-items-testimonials',
                content: 'Testimonials',
                url: 'https://oldhamgroupluxury.com/testimonials',
              },
              {
                id: 'rivanj-contact-us-item',
                content: 'Contact Us',
                url: 'mailto:theoldhamgroup@compass.com',
              },
            ],
          },
        ],
      },
    },
  ],
  footers: [
    {
      id: 'on-rivanj-footer-one',
      className: 'on-rivanj-footer on-rivanj-footer-one',
      sectionClassName: 'on-rivanj-footer-container footer-container-one',
      columns: [
        {
          id: 'on-rivanj-footer-one',
          className: 'col-lg-7 rivanj-first-col',
          items: [
            {
              id: 'f-1-col-1-item-1',
              type: 'heading',
              elementType: 'h1',
              data: 'The Oldham Group',
            },
          ],
        },
      ],
    },
    {
      id: 'on-rivanj-footer-two',
      className: 'on-rivanj-footer on-rivanj-footer-two',
      sectionClassName: 'on-rivanj-footer-container footer-container-two',
      columns: [
        {
          id: 'on-rivanj-footer-two',
          className: 'on-rivanj-footer-two-column map-column ',
          items: [
            {
              id: 'f-2-col-1-item-socials-1',
              className: 'on-rivanj-footer-socials',
              itemClassName: 'on-rivanj-footer-socials-item',
              type: 'socialIcons',
              items: [
                {
                  id: 'rivanj-map',
                  iconTypeClassName: 'fa',
                  iconClassName: 'map-marked-alt',
                },
              ],
            },
            {
              id: 'f-2-col-1-item-2',
              type: 'heading',
              elementType: 'h2',
              data: 'ADDRESS',
              className: 'rivanj-col-item',
            },
            {
              id: 'f-2-col-1-item-3',
              type: 'heading',
              elementType: 'p',
              data: '16268 LOS GATOS BLVD. LOS GATOS, CA 90532',
              className: 'rivanj-col-item col-address',
            },
          ],
        },
        {
          id: 'on-rivanj-footer-two',
          className: 'on-rivanj-footer-two-column phone-column ',
          items: [
            {
              id: 'f-2-col-1-item-socials-2',
              className: 'on-rivanj-footer-socials',
              itemClassName: 'on-rivanj-footer-socials-item',
              type: 'socialIcons',
              items: [
                {
                  id: 'rivanj-phone',
                  iconTypeClassName: 'fa',
                  iconClassName: 'phone',
                  url: '',
                },
              ],
            },
            {
              id: 'f-2-col-1-item-4',
              type: 'heading',
              elementType: 'h2',
              data: 'PHONE NUMBER',
              className: 'rivanj-col-item',
            },
            {
              id: 'f-2-col-1-item-5',
              type: 'heading',
              elementType: 'p',
              data: '(888) 842-4930',
              className: 'rivanj-col-item rivanj-col-item-line',
              url: 'tel:(888) 842-4930',
            },
          ],
        },
        {
          id: 'on-rivanj-footer-two',
          className: 'on-rivanj-footer-two-column',
          items: [
            {
              id: 'f-2-col-1-item-socials-3',
              className: 'on-rivanj-footer-socials',
              itemClassName: 'on-rivanj-footer-socials-item',
              type: 'socialIcons',
              items: [
                {
                  id: 'rivanj-mail',
                  iconTypeClassName: 'fa',
                  iconClassName: 'envelope',
                  url: '',
                },
              ],
            },
            {
              id: 'f-2-col-1-item-6',
              type: 'heading',
              elementType: 'h2',
              data: 'EMAIL',
              className: 'rivanj-col-item',
            },
            {
              id: 'f-2-col-1-item-7',
              type: 'heading',
              elementType: 'h2',
              data: 'THEOLDHAMGROUP@COMPASS.COM',
              className: 'rivanj-col-item rivanj-col-item-line',
              url: 'mailto:theoldhamgroup@compass.com',
            },
          ],
        },
        {
          id: 'on-rivanj-footer-two',
          className: 'on-rivanj-footer-two-column',
          items: [
            {
              id: 'f-2-col-1-item-socials-3',
              className: 'on-rivanj-footer-socials',
              itemClassName: 'on-rivanj-footer-socials-item',
              type: 'socialIcons',
              items: [
                {
                  id: 'rivanj-clock',
                  iconTypeClassName: 'fa',
                  iconClassName: 'clock',
                  url: '',
                },
              ],
            },
            {
              id: 'f-2-col-1-item-6',
              type: 'heading',
              elementType: 'h2',
              data: 'OFFICE HOURS',
              className: 'rivanj-col-item',
            },
            {
              id: 'f-2-col-1-item-7',
              type: 'heading',
              elementType: 'p',
              data: 'MONDAY - FRIDAY, 9AM-6PM',
              className: 'rivanj-col-item',
            },
          ],
        },
      ],
    },
    {
      id: 'on-rivanj-footer-three',
      className: 'on-rivanj-footer on-rivanj-footer-three',
      sectionClassName: 'on-rivanj-footer-container footer-container-three',
      columns: [
        {
          id: 'on-rivanj-footer-three',
          className: 'rivanj-first-col',
          items: [
            {
              id: 'f-3-col-1-item-1',
              type: 'image',
              className: 'on-rivanj-footer-logo',
              style: { width: '210px' },
              url: 'https://si-homelight.s3.amazonaws.com/sites/rivanj/logo-footer.webp',
            },
          ],
        },
        {
          id: 'on-rivanj-footer-three',
          className: 'rivanj-second-col',
          items: [
            {
              id: 'f-3-col-1-item-3',
              type: 'itemContent',
              className: 'pb-2',
              items: [
                {
                  content:
                    'In partnering with Compass, we are able to offer clients unparalleled support along with the latest in cutting-edge technology. Their strong business ethics and strategic thinking – coupled with the group’s of over 150 years of combined real estate experience – create a unique residential sales team. Our clients appreciate our enthusiasm, integrity and personal approach.',
                },
              ],
            },
            {
              id: 'f-3-col-1-item-5',
              type: 'heading',
              elementType: 'p',
              data: 'JENNIFER OLDHAM - CA DRE# 01325650',
              className: 'mb-0',
            },
            {
              id: 'f-3-col-1-item-6',
              type: 'heading',
              elementType: 'p',
              data: 'ANDREW OLDHAM - CA DRE# 01207289',
              className: 'mb-3 pb-2',
            },
            {
              id: 'f-3-col-1-item-7',
              type: 'heading',
              data: 'Disclaimer',
              url: 'https://oldhamgroupluxury.com/disclaimer',
            },
          ],
        },
      ],
    },
    {
      id: 'on-rivanj-footer-four',
      className: 'on-rivanj-footer on-rivanj-footer-four',
      sectionClassName: 'on-rivanj-footer-container footer-container-four',
      columns: [
        {
          id: 'on-rivanj-footer-four-col',
          className: 'col-lg-6 rivanj-last-first-col',
          items: [
            {
              id: 'f-4-col-1',
              type: 'itemContent',
              className: 'mb-0 pt-sm-4 pt-md-0',
              style: {},
              items: [
                {
                  content: 'Copyright © 2022  ',
                },
                {
                  content: ' | ',
                },
                {
                  content: 'Policy',
                  url: 'https://oldhamgroupluxury.com/terms-and-conditions',
                  className: 'on-rivanj-privacy-link',
                },
              ],
            },
          ],
        },
        {
          id: 'on-rivanj-footer-four-col',
          className: 'col-lg-6 rivanj-last-first-col',
          items: [
            {
              id: 'f-4-col-2',
              type: 'itemContent',
              className: 'mb-0 pt-sm-4 pt-md-0 on-rivanj-powered',
              style: {},
              items: [
                {
                  content: 'Powered by ',
                },
                {
                  content: 'Luxury Presence',
                  url: 'https://www.luxurypresence.com/',
                  className: 'on-rivanj-luxury-link',
                },
              ],
            },
            {
              id: 'f-4-col-2-socials',
              type: 'socialIcons',
              className: 'on-rivanj-last-footer-socials',
              itemClassName: 'on-rivanj-last-footer-socials-item',
              items: [
                {
                  id: 'rivanj-facebook',
                  iconTypeClassName: 'fab',
                  iconClassName: 'facebook-f',
                  url: 'https://www.facebook.com/CompassOldhamGroup/',
                },
                {
                  id: 'rivanj-instagram',
                  iconTypeClassName: 'fab',
                  iconClassName: 'instagram',
                  url: 'https://www.instagram.com/oldhamgroupcompass/',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  substitute: {},
  replace: {
    'tradeIn.hero.className': 'heroClassName',
    'cashOffer.hero.className': 'heroClassName',
  },
  siteData: {
    agency: 'The Oldham Group',
    agentDescription: '',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/rivanj/agent.jpg',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/rivanj/hero-bg.jpg',
    heroClassName: 'on-rivanj-hero',
  },
};
